import {applyMiddleware, createStore} from "redux";
import rootred from "../reducer/main";
import {composeWithDevTools} from "@redux-devtools/extension";
import {thunk} from "redux-thunk";

const middleware = [thunk]

const store = createStore(
    rootred,
    composeWithDevTools(applyMiddleware(...middleware))
);


export default store;