import axios from 'axios';
import Auth from "../redux/auth";

const apiClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});

const addAuthToken = (config) => {
    const masterToken = sessionStorage.getItem('master');
    const partnerToken = sessionStorage.getItem('partner');
    const userToken = sessionStorage.getItem('user');

    const token = masterToken || partnerToken || userToken;

    if (token) {
        config.headers['Authorization'] = `Bearer ${token.replace(/"/g, '')}`;
    }
    return config;
};

apiClient.interceptors.request.use(addAuthToken);
apiClient.interceptors.response.use(response => {
    // 응답 확인 및 처리
    if (response.data.code === 80) {
        alert('재 로그인 해주십시오.')
        Auth.logout()
        return window.location.reload();
    } else {
        return response
    }

}, error => {
    console.error('응답 오류:', error);
    return Promise.reject(error);
});


export default apiClient;