import {jwtDecode} from "jwt-decode";

const Auth = {
    getSession: (userType = 'master') => {
        return typeof window !== 'undefined' ? sessionStorage.getItem(userType) : null;
    },

    destroySession: (userType = 'master') => {
        if (typeof window !== 'undefined') {
            sessionStorage.removeItem(userType);
        }
    },

    setLogin: (userType = 'master', token) => {
        if (typeof window !== 'undefined' && token) {
            sessionStorage.setItem(userType, token);
        }
    },

    logout: () => typeof window !== 'undefined' ? sessionStorage.clear() : null,

    getUserInfo: (userType = 'master') => {
        const token = sessionStorage.getItem(userType);
        if (token) {
            try {
                const decoded = jwtDecode(token);
                return decoded;
            } catch (error) {
                console.error('Error decoding token:', error);
                return null;
            }
        } else {
            return null;
        }
    },

    getUserType: () => {
        if (typeof window === 'undefined') return null;

        if (sessionStorage.getItem('master')) return 'master';
        if (sessionStorage.getItem('partner')) return 'partner';
        if (sessionStorage.getItem('user')) return 'user';

        return null;
    }

};

export default Auth;

