import apiClient from "../../utils/apiClient";

export const LOGOUT = 'LOGOUT';

export const LOGIN = 'LOGIN';

export const SET_MENU = 'SET_MENU';

export function logout(userType) {
    return {type: LOGOUT, payload: {userType}};
}

export function login(session, userType) {
    return {type: LOGIN, payload: {...session, userType}};
}

export function setMenu(menu) {
    return {type: SET_MENU, payload: menu};
}

export function systemLogoutThunk(navigate) {
    return async function (dispatch) {
        const result = await apiClient.post('/system/logout', {});
        if (result) {
            dispatch(logout('master'));
            navigate('/')
        }
    };
}

export function systemLoginThunk(account, password, navigate) {
    return async function (dispatch) {
        try {
            const result = await apiClient.post('/system/login', {
                system_account: account,
                password: password,
            });

            if (result.data.code === 0) {
                if (result.data.agent_level >= 7) {
                    dispatch(login({auth: result.data.token}, 'master'));
                    navigate('/master-management');
                } else {
                    alert('접근 권한이 없습니다.');
                }
            }
            else {
                alert('아이디나 비밀번호가 틀립니다.');
                window.location.reload();
            }
        } catch (error) {
            console.error('Login error:', error);
            alert('잠시 후 다시 시도해 주세요. 해당문제가 계속 발생하면 고객센터로 문의바랍니다.');
        }
    };
}