import React, {lazy} from 'react';
import {Suspense} from "react";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {Spinner} from "@nextui-org/react";

const Login = lazy(() => import("../pages"));
// const Dashboard = lazy(() => import("../pages/dashboard"));
const Users = lazy(() => import("../pages/users"));
const Partner = lazy(() => import("../pages/partner"));
const BettingList = lazy(() => import("../pages/bettingList"));
const MasterManagement = lazy(() => import("../pages/masterManagement"));


const Router = () => {
    return (
        <BrowserRouter>
            <Suspense fallback={
                <div className="fixed inset-0 bg-black bg-opacity-10 flex items-center justify-center">
                    <Spinner label="Loading..." color="danger"/>
                </div>
            }>
                <Routes>
                    <Route path="/" element={<Login />}/>
                    {/*<Route path="/dashboard" element={<Dashboard />}/>*/}
                    <Route path="/user-list" element={<Users />}/>
                    <Route path='/partner' element={<Partner/>}/>
                    <Route path="/betting-list" element={<BettingList />}/>
                    <Route path="/master-management" element={<MasterManagement />}/>
                </Routes>
            </Suspense>
        </BrowserRouter>
    );
};

export default Router;