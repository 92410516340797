import Auth from "../auth"
import { LOGOUT, LOGIN } from "../action/loginAction";

export const initialState = {
    count: 0,
    session: null,
    isAuth: false,
};

export default function loginReducer(state = initialState, action) {
    switch (action.type) {
        case LOGOUT:
            const logoutUserType = action.payload?.userType || 'master';
            Auth.destroySession(logoutUserType);

            return {
                ...state,
                session: '',
                isAuth: false,
            };
        case LOGIN:
            const { auth, userType } = action.payload;
            Auth.setLogin(userType, auth);

            return {
                ...state,
                session: Auth.getSession(userType),
                isAuth: !!Auth.getSession(userType),
            };
        default:
            return state;
    }
}